import { configureStore } from "@reduxjs/toolkit";

import storeSlice from './Redux/Slices/StoreSlices';
import couponSlices from "./Redux/Slices/CouponSlices";
import categorySlice from "./Redux/Slices/CategorySlices"

const store = configureStore({
  reducer: {
      store : storeSlice,
      coupon : couponSlices,
      category : categorySlice
    },
   
})

export default store