import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCategory = createAsyncThunk("cashback/category", async () => {

    const url = `${process.env.REACT_APP_ADMIN_URL}/cashback/getAllCategory`
    const res = await fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return res.json()

})

const categorySlice = createSlice({
    name : 'category',
    initialState : {
        isLoading : false,
        isError : null,
        data : null
    },
    extraReducers : (builder) => {
        builder.addCase(getCategory.pending, (state) => {
            state.isLoading = true
        });

        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        });

        builder.addCase(getCategory.rejected, (state, action) => {
            state.isLoading = false
            state.isError = action.error.message
        });
    }
})

export default categorySlice.reducer